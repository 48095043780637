import Notification from '@/components/notifications/Notification';
import { motion } from 'framer-motion';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useLaravelReactI18n } from 'laravel-react-i18n';

const successNotificationDurationMotion = {
    initial: { width: '0%' },
    animate: { width: '100%' },
    exit: { width: '100%' },
    transition: {
        ease: 'linear',
        duration: 5,
    },
};

const SuccessNotification = ({ title, description, onClose }) => {
    const translate = useLaravelReactI18n().t;

    return (
        <Notification className={'relative flex items-center gap-5'} onClose={onClose}>
            <div className={'flex items-center justify-center bg-gray-800 w-16 aspect-square rounded-full'}>
                <CheckIcon className={'h-8 aspect-square'} />
            </div>

            <div>
                <h1 className={'font-bold text-md'}>{title}</h1>

                <p className={'text-gray-200 text-sm'}>{translate(`snackbar.${description}`)}</p>

                <div className={'h-1 w-full absolute bottom-0 right-0 overflow-hidden bg-gray-600'}>
                    <motion.div onAnimationComplete={onClose} {...successNotificationDurationMotion}>
                        <div className={'bg-gray-300 h-2.5'}></div>
                    </motion.div>
                </div>
            </div>
        </Notification>
    );
};

export default SuccessNotification;
