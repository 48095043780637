import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

const notificationMotion = {
    initial: { opacity: 0, y: '-100%' },
    animate: { opacity: 1, y: '0%', scale: 1 },
    exit: { opacity: 0, scale: 0 },
    transition: {
        duration: 0.4,
    },
};

const Notification = ({ children, onClose, className }) => {
    return (
        <motion.div className={twMerge('relative max-w-md w-full rounded-lg bg-gray-700 text-white right-0 p-5 overflow-hidden shadow-md', className)} {...notificationMotion}>
            {children}
        </motion.div>
    );
};

export default Notification;
