const click = {
    whileTap: {
        scale: 0.975,
    },
    transition: {
        type: 'spring',
        stiffness: 400,
        damping: 16,
    },
};

const backdrop = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
    transition: {
        duration: 0.2,
    },
};

const loader = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
    transition: {
        delay: 0.2,
        duration: 0.2,
    },
};

const modal = {
    initial: {
        opacity: 0,
        y: -100,
    },
    animate: {
        opacity: 1,
        y: 0,
    },
    exit: {
        opacity: 0,
        y: 1000,
    },
    transition: {
        duration: 0.2,
    },
};

export const dialog = {
    initial: {
        opacity: 0,
        y: -100,
    },
    animate: {
        opacity: 1,
        y: 0,
    },
    exit: {
        opacity: 0,
        y: 1000,
    },
    transition: {
        duration: 0.2,
    },
};

export const drawer = {
    initial: {
        opacity: 0,
        x: '100%',
    },
    animate: {
        opacity: 1,
        x: '0%',
    },
    exit: {
        opacity: 0,
        x: '100%',
    },
    transition: {
        duration: 0.3,
    },
};

export { click, backdrop, modal, loader };
