import { createContext, useContext, useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import SuccessNotification from '@/components/notifications/SuccessNotification';
import ErrorNotification from '@/components/notifications/ErrorNotification';
import WarningNotification from '@/components/notifications/WarningNotification';
import { usePage } from '@inertiajs/react';

const notificationStatusesComponents = {
    success: SuccessNotification,
    warning: WarningNotification,
    error: ErrorNotification,
};

export const NotificationContext = createContext(null);

export const useNotification = () => {
    const context = useContext(NotificationContext);

    return context;
};

export const NotificationProvider = ({ children }) => {
    const { notification } = usePage().props;
    const [activeNotifications, setActiveNotifications] = useState([]);

    useEffect(() => {
        if (!notification) return;

        open(notificationStatusesComponents[notification.status], { ...notification });
    }, [notification]);

    const open = (Component, properties = {}) => {
        const id = Date.now();

        setActiveNotifications((prevNotifications) => [...prevNotifications, { id, Component, properties }]);
    };

    const close = (id) => {
        setActiveNotifications((prevNotifications) => prevNotifications.filter((notification) => notification.id !== id));
    };

    return (
        <NotificationContext.Provider value={{ open, close }}>
            <div className={'fixed top-10 left-0 w-full z-30 gap-5 flex flex-col items-center pointer-events-none flex-col-reverse'}>
                <AnimatePresence>
                    {activeNotifications.map((notification) => {
                        const { id, Component, properties } = notification;

                        return <Component key={`notification-${id}`} onClose={() => close(id)} {...properties} />;
                    })}
                </AnimatePresence>
            </div>

            {children}
        </NotificationContext.Provider>
    );
};
